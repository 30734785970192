<template>
  <slot :url="url" :label="label" :fileType="fileType"></slot>
  <div class="container">
    <el-row justify="center">
      <el-col :xs="24"  :sm="24" :md="24" :lg="20">
        <el-card shadow="hover" class="file-card">
         <el-row class="align-items-center">

         
            <el-col :xs="4"  :sm="2" :md="2" :lg="2">
              <font-awesome-icon
                :icon="icon"
                :class="iconCSS"
              ></font-awesome-icon>
            </el-col>
            <el-col :xs="11"  :sm="12" :md="12" :lg="12" class=" text-start">{{ label }}</el-col>
            <el-col :xs="9"  :sm="10" :md="10" :lg="10" class="text-end">
              <a :href="url" download
                ><el-button type="success">Download</el-button></a
              >
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { faFilePdf, faFileImage } from "@fortawesome/free-regular-svg-icons";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  props: ["url", "label", "fileType"],
  components: { FontAwesomeIcon },
  data() {
    return {
      downloadFile: faFileDownload,
      pdfFile: faFilePdf,
      imageFile: faFileImage,
      icon: null,
      iconCSS: null,
    };
  },
  created() {
    this.iconSelector();
  },
  methods: {
    iconSelector() {
      if (this.fileType === "pdf") {
        this.icon = this.pdfFile;
        this.iconCSS = "pdf-icon";
      } else {
        this.icon = this.imageFile;
        this.iconCSS = "image-icon";
      }
    },
  },
};
</script>

<style scoped>
.pdf-icon {
  font-size: 50px;
  color: red;
}
.image-icon {
  font-size: 50px;
}
</style>