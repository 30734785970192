<template>
  <div class="row mt-5 mb-5 text-center">
    <div class="col">
      <h2>Area documentale</h2>
    </div>
  </div>
  <div class="container mt-5">
    <dl>
      <dt v-for="item in urls" :key="item">
        <file
          :url="item.url"
          :label="item.label"
          :fileType="item.fileType"
        ></file>
      </dt>
    </dl>
  </div>
</template>

<script>
import File from "../../components/Download/User/DownloadFile.vue";
export default {
  components: { File },
  data() {
    return {
      urls: [
      ],
    };
  },
};
</script>

<style scoped>
</style>